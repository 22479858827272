import { useMemo } from "react"
import styled from "styled-components"

import { ParadiseDeviceCVars } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDeviceOverview/ParadiseDeviceCVars/ParadiseDeviceCVars"
import { ParadiseDeviceFirmware } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDeviceOverview/ParadiseDeviceFirmware/ParadiseDeviceFirmware"
import { ParadiseDeviceTags } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDeviceOverview/ParadiseDeviceTags/ParadiseDeviceTags"
import { ParadiseOverviewExpandable } from "src/components/Paradise/ParadiseOverviewExpandable"
import { ParadiseSettingStatic } from "src/components/Paradise/ParadiseSettings/ParadiseSettingStatic"
import { ParadiseSettingText } from "src/components/Paradise/ParadiseSettings/ParadiseSettingText"
import { ParadiseSettingToggle } from "src/components/Paradise/ParadiseSettings/ParadiseSettingToggle"
import { StatusWrapper } from "src/components/Paradise/sharedStyles"
import { CopyWrapper } from "src/components/Paradise/sharedStyles"
import { TSettingContainerOnSaveReturnType } from "src/components/Settings/Setting/SettingContainer"
import { getDeviceHardwareType } from "src/data/devices/logic/deviceLogic"
import { usePutDevice } from "src/data/devices/queries/deviceQueries"
import {
  useFetchParadiseDeviceWarrantyStatus,
  usePutParadiseUpdateDevice,
} from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import { useParadiseDeviceCache } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueryCache"
import {
  IParadiseDevice,
  IParadiseUpdateDeviceBody,
} from "src/data/paradise/paradiseDevices/types/paradiseDeviceQueryTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { isSuperAdmin } from "src/data/user/logic/accessLogic"
import { Routes } from "src/router/routes"
import { colorsV2 } from "src/ui/colors"
import { CopyText } from "src/ui/CopyText/CopyText"
import CheckIcon from "src/ui/icons/checkmark-base.svg"
import XIcon from "src/ui/icons/x.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { formatDate } from "src/utils/l10n"

export function ParadiseDeviceOverview({
  device,
}: {
  device: IParadiseDevice
}) {
  const fetchDeviceWarrantyStatus = useFetchParadiseDeviceWarrantyStatus({
    deviceId: device.device_id,
  })
  const warrantyStatus = fetchDeviceWarrantyStatus.data

  const putParadiseUpdateDevice = usePutParadiseUpdateDevice()
  const putUpdateDevice = usePutDevice("paradise")
  const paradiseDeviceCache = useParadiseDeviceCache()

  const loggedInUser = useGetUser()

  async function updateDevice(
    body: IParadiseUpdateDeviceBody
  ): TSettingContainerOnSaveReturnType {
    try {
      await putParadiseUpdateDevice.mutateAsync({
        deviceId: device.device_id,
        body,
      })

      return {
        isSuccess: true,
      }
    } catch (error) {
      return {
        isSuccess: false,
      }
    }
  }

  // This uses v8 due to dashboard not updating this
  async function updatePlacedOutdoors(
    placedOutdoors: boolean
  ): TSettingContainerOnSaveReturnType {
    try {
      await putUpdateDevice.mutateAsync({
        id: device.device_id,
        data: {
          placed_outdoors: placedOutdoors,
        },
      })
      paradiseDeviceCache.invalidateParadiseDevice(device.device_id)

      return {
        isSuccess: true,
      }
    } catch (error) {
      return {
        isSuccess: false,
      }
    }
  }

  const warrantyValue = useMemo(() => {
    if (warrantyStatus?.warranty_status) {
      const expiryDate = formatDate({
        date: warrantyStatus.expires_at,
        excludeTime: true,
      })

      if (warrantyStatus.warranty_status === "in_warranty") {
        return `Active until ${expiryDate} (${warrantyStatus.days_left} days left)`
      } else if (warrantyStatus.warranty_status === "out_of_warranty") {
        return `Expired on ${expiryDate} (${
          warrantyStatus.days_left * -1
        } days ago)`
      }
    } else {
      return "-"
    }
  }, [warrantyStatus])

  const hardwareType = getDeviceHardwareType(device.hardware_version)

  return (
    <ParadiseOverviewExpandable rawJson={device}>
      <ParadiseSettingStatic
        title="Mac"
        value={
          <CopyWrapper>
            {device.device_mac ? (
              <>
                <InternalLink
                  to={{
                    pathname: Routes.ParadiseDevices.location().pathname,
                    search: `?device_mac=${device.device_mac}`,
                  }}
                >
                  {device.device_mac}
                </InternalLink>
                <CopyText
                  value={device.device_mac}
                  copiedText="Copied device mac"
                  width={18}
                />
              </>
            ) : (
              <MText variant="bodyS">No Mac</MText>
            )}
            <StatusWrapper>
              <IconWrapper>
                {device.active ? (
                  <CheckIcon width={15} color={colorsV2.systemGood} />
                ) : (
                  <XIcon width={12} color={colorsV2.systemError} />
                )}
              </IconWrapper>
              <MText variant="nano">
                {device.active ? "Active" : "Inactive"}
              </MText>
            </StatusWrapper>
          </CopyWrapper>
        }
      />
      <ParadiseSettingStatic
        title="Warranty status"
        value={
          fetchDeviceWarrantyStatus.isLoading ? (
            <MSkeleton width="10ch" />
          ) : (
            warrantyValue
          )
        }
      />
      <ParadiseSettingStatic
        title="Owner"
        value={
          <CopyWrapper>
            <InternalLink to={Routes.ParadiseUser.location(device.owner)}>
              {device.owner}
            </InternalLink>
            <CopyText value={device.owner} copiedText="Copied" width={18} />
          </CopyWrapper>
        }
      />
      <ParadiseSettingStatic
        title="Home"
        value={
          device.home ? (
            <InternalLink to={Routes.ParadiseHome.location(device.home)}>
              {device.home}
            </InternalLink>
          ) : (
            "-"
          )
        }
      />
      <ParadiseSettingText
        title="Description"
        value={device.description}
        onSave={(value) => {
          return updateDevice({
            description: value,
          })
        }}
        disabled={!isSuperAdmin(loggedInUser.roles)}
      />

      <ParadiseDeviceTags
        currentTags={device.tags}
        deviceId={device.device_id}
        disabled={!isSuperAdmin(loggedInUser.roles)}
      />

      <ParadiseSettingText
        title="Room type"
        value={device.configuration.room_type ?? ""}
        onSave={(value) => {
          return updateDevice({
            room_type: value,
          })
        }}
        disabled={!isSuperAdmin(loggedInUser.roles)}
      />
      {device.outdoor_support && (
        <ParadiseSettingToggle
          title="Placed outdoors"
          value={device.placed_outdoors}
          onSave={(value) => {
            return updatePlacedOutdoors(!!value)
          }}
          disabled={!isSuperAdmin(loggedInUser.roles)}
        />
      )}
      {device.wlan.ssid && (
        <ParadiseSettingStatic title="WLAN SSID" value={device.wlan.ssid} />
      )}
      {device.wlan.bssid && (
        <ParadiseSettingStatic title="WLAN BSSID" value={device.wlan.bssid} />
      )}

      <ParadiseDeviceFirmware
        deviceId={device.device_id}
        installedFirmwareNumber={device.firmware.installed}
        wantedFirmwareNumber={device.firmware.wanted}
        hardwareVersion={device.hardware_version}
        disabled={!isSuperAdmin(loggedInUser.roles)}
      />
      <ParadiseSettingStatic
        title="Hardware"
        value={
          hardwareType.revision
            ? `${hardwareType.type} ${hardwareType.revision} (${device.hardware_version})`
            : device.hardware_version
        }
      />

      <ParadiseDeviceCVars
        currentCVars={device.cvars}
        deviceId={device.device_id}
        disabled={!isSuperAdmin(loggedInUser.roles)}
      />
    </ParadiseOverviewExpandable>
  )
}

const IconWrapper = styled.div`
  line-height: 0;
`
