import { useMemo, useState } from "react"
import styled from "styled-components"

import { Select } from "@material-ui/core"

import { ParadiseDeviceClearFirmware } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDeviceOverview/ParadiseDeviceFirmware/ParadiseDeviceClearFirmware"
import { ParadiseDeviceSelectFirmware } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDeviceOverview/ParadiseDeviceFirmware/ParadiseDeviceSelectFirmware"
import {
  SettingContainer,
  TSettingContainerOnSaveReturnType,
} from "src/components/Settings/Setting/SettingContainer"
import { usePutSetDeviceFirmware } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import { useFetchParadiseFirmwares } from "src/data/paradise/paradiseFirmwares/queries/paradiseFirmwareQueries"
import { IParadiseFirmwaresListResponse } from "src/data/paradise/paradiseFirmwares/types/paradiseFirmwareQueryTypes"
import { IMSelectResult } from "src/ui/MSelect/MSelect"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { Maybe } from "src/utils/tsUtil"

type Actions = "select" | "clear"

export interface FirmwareDropdownResult extends IMSelectResult {
  tags: string[]
  description: string
  fwNumber: number
}

export function ParadiseDeviceFirmware({
  deviceId,
  installedFirmwareNumber,
  wantedFirmwareNumber,
  hardwareVersion,
  disabled,
}: {
  deviceId: string
  installedFirmwareNumber: number
  wantedFirmwareNumber: number | undefined
  hardwareVersion: number
  disabled?: boolean
}) {
  const [selectedAction, setSelectedAction] = useState<Actions>("select")
  const [selectedFirmware, setSelectedFirmware] =
    useState<Maybe<FirmwareDropdownResult>>(null)

  const fetchFirmwares = useFetchParadiseFirmwares({
    filter: {
      hardware_version: hardwareVersion,
    },
  })
  const allFirmwares = fetchFirmwares.data

  const putSetDeviceFirmware = usePutSetDeviceFirmware()

  async function handleSave(): TSettingContainerOnSaveReturnType {
    try {
      const firmwareVersion =
        selectedAction === "select" && selectedFirmware
          ? selectedFirmware.fwNumber
          : null

      await putSetDeviceFirmware.mutateAsync({
        deviceId,
        body: {
          version: firmwareVersion,
        },
      })

      return {
        isSuccess: true,
      }
    } catch (error) {
      return {
        isSuccess: false,
      }
    }
  }

  const currentlyWantedFirmware = useMemo(() => {
    if (allFirmwares && wantedFirmwareNumber) {
      return convertFirmwareDropDownResult(
        allFirmwares.firmwares,
        wantedFirmwareNumber
      )
    }

    return null
  }, [wantedFirmwareNumber, allFirmwares])

  return (
    <SettingContainer
      title="Firmware"
      titleProps={{
        type: "nano",
      }}
      gap={spacing.XS2}
      InputComponent={() => (
        <InputWrapper>
          <div>
            <MText variant="bodyS" color="secondary">
              Installed
            </MText>
            <MText variant="body">{installedFirmwareNumber}</MText>
          </div>
          <div>
            <MText variant="subtitle">Select action</MText>
            <Select
              native
              value={selectedAction}
              onChange={(e) => setSelectedAction(e.target.value as Actions)}
              fullWidth
            >
              <option value="select">Set wanted firmware</option>
              <option value="clear">Clear wanted firmware</option>
            </Select>
          </div>
          {selectedAction === "select" ? (
            <ParadiseDeviceSelectFirmware
              initialFirmware={currentlyWantedFirmware}
              hardwareVersion={hardwareVersion}
              onSelect={(selected) => {
                setSelectedFirmware(selected)
              }}
            />
          ) : (
            <ParadiseDeviceClearFirmware
              currentWantedFirmware={currentlyWantedFirmware}
            />
          )}
        </InputWrapper>
      )}
      displayValue={
        <DisplayValueWrapper>
          <div>
            <MText variant="bodyS" color="secondary">
              Installed
            </MText>
            <MText variant="body">{installedFirmwareNumber}</MText>
          </div>
          <div>
            <MText variant="bodyS" color="secondary">
              Wanted
            </MText>
            <MText variant="body">{wantedFirmwareNumber ?? "-"}</MText>
          </div>
        </DisplayValueWrapper>
      }
      onSave={handleSave}
      onClose={() => {
        setSelectedAction("select")
        setSelectedFirmware(null)
      }}
      preventSubmitOnEnter
      disabled={disabled}
    />
  )
}

const InputWrapper = styled.div`
  display: grid;
  gap: ${spacing.L};
`

const DisplayValueWrapper = styled.div`
  display: flex;
  gap: ${spacing.L};
`

function convertFirmwareDropDownResult(
  allFirmwares: IParadiseFirmwaresListResponse["firmwares"],
  wantedFirmwareNumber: number
): Maybe<FirmwareDropdownResult> {
  const wantedFirmware = allFirmwares.find(
    (f) => f.name === wantedFirmwareNumber
  )

  if (wantedFirmware) {
    return {
      id: wantedFirmware.description,
      name: wantedFirmware.description,
      tags: wantedFirmware.tags,
      fwNumber: wantedFirmware.name,
      description: wantedFirmware.description,
    }
  }

  return null
}
