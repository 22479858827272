import { useState } from "react"

import { ParadiseDeviceTransferDialog } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDeviceTransferDialog"
import { ActionsWrapper } from "src/components/Paradise/sharedStyles"
import { RequireRoles } from "src/components/RestrictedRoles/RequireRoles"
import { firmwareCrashReportUrl, kibanaDeviceUrl } from "src/constants/hrefs"
import { IParadiseDevice } from "src/data/paradise/paradiseDevices/types/paradiseDeviceQueryTypes"
import { UserRoles } from "src/data/user/user"
import { Routes } from "src/router/routes"
import { MButton } from "src/ui/Button/MButton"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { InternalLink } from "src/ui/Link/InternalLink"

export function ParadiseDeviceActions({ device }: { device: IParadiseDevice }) {
  const [showTransferDeviceDialog, setShowTransferDeviceDialog] =
    useState(false)

  return (
    <div>
      <RequireRoles roles={[UserRoles.SALES_ADMIN]}>
        <InternalLink
          to={Routes.ParadiseDeviceReadings.location({
            deviceId: device.device_id,
            type: "temperature",
          })}
        >
          Sensor graphs
        </InternalLink>
      </RequireRoles>
      <RequireRoles roles={[UserRoles.SUPERADMIN]}>
        <ActionsWrapper>
          <ExternalLink href={kibanaDeviceUrl(device)}>Kibana</ExternalLink>
          <ExternalLink href={firmwareCrashReportUrl(device.device_mac)}>
            Firmware crash report
          </ExternalLink>
          <InternalLink
            to={Routes.ParadiseDeviceReadings.location({
              deviceId: device.device_id,
              type: "temperature",
            })}
          >
            Sensor graphs
          </InternalLink>
          <MButton
            variant="subtle"
            size="small"
            onClick={() => setShowTransferDeviceDialog(true)}
          >
            Transfer device
          </MButton>
        </ActionsWrapper>
        <ParadiseDeviceTransferDialog
          open={showTransferDeviceDialog}
          onClose={() => setShowTransferDeviceDialog(false)}
          homeId={device.home}
          deviceId={device.device_id}
        />
      </RequireRoles>
    </div>
  )
}
